<section id="login-container">
  <mat-card id="admin-login">
    <mat-card-title>Admin Login</mat-card-title>
    <mat-card-content>

      <mat-card id="guest-welcome">
        <div id="login-message">
          <h4>Take a peek under the hood!</h4>
          <p class="h4">Log in as guest to see how song data is stored</p>
          <mat-card id="login-credentials">
            <p class="h4"><b>Email:</b>&nbsp;&nbsp;guest@chordbomb.com</p>
            <p class="h4"><b>Password:</b>&nbsp;&nbsp;123456</p>
          </mat-card>
        </div>
      </mat-card>

      <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="onLogin()"
        (keyup)="onKeyUp($event)">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="text" name="email">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" type="password">
        </mat-form-field>
      </form>

      <mat-error *ngIf="error">
        {{ error }}
      </mat-error>

      <button mat-raised-button id="login-btn" [disabled]="!loginForm.valid"
        (click)="onLogin()">
        Log in
      </button>

    </mat-card-content>
  </mat-card>
</section>
